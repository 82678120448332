import React from 'react';
const Home = React.lazy(() => import('./views/Home/Home'));
const Logout = React.lazy(() => import('./views/Logout/Logout'));
const Login = React.lazy(() => import('./views/Login/LoginForm'));
const ResetPassword = React.lazy(
  () => import('./views/ResetPassword/ResetPasswordForm')
);

// lazy loading causes screen flicker when changing tabs
const Products = React.lazy(() => import('./views/Products/ProductsContainer'));
const Trades = React.lazy(() => import('./views/Trades/Trades'));
const Orders = React.lazy(() => import('./views/Orders/Orders'));
const Preferences = React.lazy(() => import('./views/Preferences/Preferences'));
const Blast = React.lazy(() => import('./views/Blast/Blast'));

interface RouterConfig {
  path: string;
  name: string;
  Component: any;
  subject: string;
}

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes: RouterConfig[] = [
  {
    path: '/markets',
    name: 'markets',
    Component: Products,
    subject: 'private',
  },
  {
    path: '/logout',
    name: 'Logout',
    Component: Logout,
    subject: 'public',
  },
  {
    path: '/login',
    name: 'Login',
    Component: Login,
    subject: 'public',
  },
  {
    path: '/trades/*',
    name: 'Trades',
    Component: Trades,
    subject: 'private',
  },
  {
    path: '/orders/*',
    name: 'Orders',
    Component: Orders,
    subject: 'private',
  },
  {
    path: '/preferences',
    name: 'More',
    Component: Preferences,
    subject: 'private',
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    Component: ResetPassword, //ResetPassword,
    subject: 'public',
  },
  {
    path: '/blast',
    name: 'Blast',
    Component: Blast,
    subject: 'private',
  },
  {
    path: '/',
    name: 'Home',
    Component: Home,
    subject: 'public',
  },
];

export default routes;
