import { createMuiTheme, Theme, ThemeOptions } from '@material-ui/core';
import base from './base';
import zuma from './zuma';
import fis from './fis';
import ssy from './ssy';
import arrow from './arrow';
import braemar from './braemar';
import grm from './grm';
import bunkerEx from './bunkerEx';
import lightship from './lightship';
import deepmerge from 'deepmerge';
import { UserConfigurableThemeOptions } from '../typings/theme';
import thedesk from './thedesk';
import onyx from './onyx';
import holborn from './holborn';
import threefifty from './threefifty';
import gfi from './gfi';

const venetianDomainify = (subdomain: string) =>
  `${subdomain}.venetian.zuma.dev`;

export const tenantThemeOverrides = (domain?: string): ThemeOptions => {
  const resolvedDomain = (domain || window.location.hostname).toLowerCase();

  // Braemar Domains
  if (
    resolvedDomain.includes('braemarscreen.com') ||
    resolvedDomain.includes('lngffa.com')
  ) {
    return braemar;
  }

  // Lightship domains
  if (
    resolvedDomain.includes('lightship.plus') ||
    resolvedDomain.includes('app.lightship.dk')
  ) {
    return lightship;
  }

  // Holborn Domains
  if (resolvedDomain.includes('app.holborngreendata.com')) {
    return holborn;
  }

  // BunkerEx Domains
  if (resolvedDomain.includes('bunker-ex.com')) {
    return bunkerEx;
  }

  // GRM Domains
  if (resolvedDomain.includes('grmscreen.com')) {
    return grm;
  }

  // THE DESK Domains
  if (resolvedDomain.includes('the-desk.app')) {
    return thedesk;
  }

  // GFI Domains
  if (resolvedDomain.includes('tankerffa.com')) {
    return gfi;
  }

  // Onyx domains
  if (resolvedDomain.includes('onyx.zuma.dev')) {
    return onyx;
  }

  // FIS
  if (resolvedDomain.endsWith('fisblast.com')) {
    return fis;
  }
  // ARROW
  if (resolvedDomain.endsWith('arrowship.app')) {
    return arrow;
  }
  // SSY
  if (resolvedDomain.endsWith('ssyonline.app')) {
    return ssy;
  }

  if (resolvedDomain.endsWith('threefiftymarkets.com')) {
    return threefifty;
  }

  // Fallback to Zuma
  return zuma;
};

export const createTheme = (
  userOverrides: Partial<UserConfigurableThemeOptions> = {},
  domain?: string
): Theme => {
  const tenantOverrides = tenantThemeOverrides(domain);
  const overrides = deepmerge.all([base, tenantOverrides, userOverrides]);
  return createMuiTheme(overrides);
};
